import { useRouter } from 'next/router'
import { useEffect } from 'react'

import LoadingSpinner from 'components/LoadingSpinner'
import { useUser } from 'hooks'

export default function Index() {
  const { user, loading } = useUser()
  const { push } = useRouter()

  useEffect(() => {
    if (user)
      if (user.farms?.length)
        push({
          pathname: `/farm/[farmId]/dashboard`,
          query: { farmId: user.farms?.[0]?.id },
        })
      else push(`/farm`)
  }, [user, loading])

  return <LoadingSpinner />
}
